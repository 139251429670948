<template>
  <div>
    <div class="mt-12 flex flex-col">
      <h1 class="text-gray-800 mx-auto">
        Meet the Team
      </h1>
      <div class="grid grid-cols-1 gap-y-8 pt-4 lg:mx-16 lg:max-w-none lg:grid-cols-2">
        <div class="w-full max-w-md mx-auto mb-4 bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div class="md:flex">
            <div class="md:shrink-0">
              <img
                class="h-48 w-full object-cover md:h-full md:w-48"
                src="https://plus.unsplash.com/premium_vector-1719015253440-ccedd0c117c3?q=80&w=3560&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Modern building architecture"
              >
            </div>
            <div class="p-8">
              <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                Gwen
              </div>
              <a
                href="#"
                class="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
              >I'm busy, leave a message</a>
              <!-- <p class="mt-2 text-slate-500">
                Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.
              </p> -->
            </div>
          </div>
        </div>

        <div class="w-full max-w-md mx-auto mb-4 bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div class="md:flex">
            <div class="md:shrink-0">
              <img
                class="h-48 w-full object-cover md:h-full md:w-48"
                src="https://plus.unsplash.com/premium_vector-1718630786470-6265deb1ea90?q=80&w=3560&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              >
            </div>
            <div class="p-8">
              <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                Dan
              </div>
              <a
                href="#"
                class="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
              >Aaggghhh my life is a mess</a>
              <!-- <p class="mt-2 text-slate-500">
                Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.
              </p> -->
            </div>
          </div>
        </div>


        <div class="w-full max-w-md mx-auto mb-4 bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div class="md:flex">
            <div class="md:shrink-0">
              <img
                class="h-48 w-full object-cover md:h-full md:w-48"
                src="https://plus.unsplash.com/premium_vector-1721492690407-e6cb2669aaf1?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNhdHN8ZW58MHx8MHx8fDA%3D"
                alt="Modern building architecture"
              >
            </div>
            <div class="p-8">
              <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                Piero
              </div>
              <a
                href="#"
                class="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
              >Get to work!</a>
              <!-- <p class="mt-2 text-slate-500">
                Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.
              </p> -->
            </div>
          </div>
        </div>


        <div class="w-full max-w-md mx-auto mb-4 bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div class="md:flex">
            <div class="md:shrink-0">
              <img
                class="h-48 w-full object-cover md:h-full md:w-48"
                src="https://plus.unsplash.com/premium_vector-1721649515865-ca0220d8eab1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nzd8fGNhdHN8ZW58MHx8MHx8fDA%3D"
                alt="Modern building architecture"
              >
            </div>
            <div class="p-8">
              <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                Rodney
              </div>
              <a
                href="#"
                class="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
              >I need to take a nap</a>
              <!-- <p class="mt-2 text-slate-500">
                Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.
              </p> -->
            </div>
          </div>
        </div>


        <div class="w-full max-w-md mx-auto mb-4 bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div class="md:flex">
            <div class="md:shrink-0">
              <img
                class="h-48 w-full object-cover md:h-full md:w-48"
                src="https://plus.unsplash.com/premium_vector-1721649515752-94bcdb8057fa?q=80&w=1760&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Modern building architecture"
              >
            </div>
            <div class="p-8">
              <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                Shay
              </div>
              <a
                href="#"
                class="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
              >Can't wait for dinner</a>
              <!-- <p class="mt-2 text-slate-500">
                Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>